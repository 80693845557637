// Use type-only imports with dynamic references to avoid requiring the packages
type SentryNode = typeof import('@sentry/node');
type SentryNext = typeof import('@sentry/nextjs');
type SentryType = SentryNode | SentryNext;

// Extend the globalThis interface instead of using declare global
interface GlobalThisWithSentry {
  __sentry?: SentryType | null;
}

// Cast globalThis to our extended interface
const global = globalThis as unknown as GlobalThisWithSentry;

// Initialize if not already set
if (typeof global.__sentry === 'undefined') {
  global.__sentry = null;
}

export function initializeSentry(sentry: SentryType): void {
  global.__sentry = sentry;
}

// Proxy to maintain the same API as Sentry
export default new Proxy({} as SentryType, {
  get(_, prop: string) {
    if (!global.__sentry) {
      console.warn(
        `Sentry not initialized in ${typeof window !== 'undefined' ? 'client' : 'server'} environment. Please ensure initializeSentry is called in the appropriate config file.`
      );
      return (...args: unknown[]) => args[0];
    }
    return global.__sentry[prop];
  },
});
